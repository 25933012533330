import { Suspense, lazy } from "react";
import Box from "@mui/material/Box";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import NavBar from "./common/NavBar";
import Footer from "./common/Footer";

const Overview = lazy(() => import("./routes/Overview"));
const Docs = lazy(() => import("./routes/Docs"));
const About = lazy(() => import("./routes/About"));
const Posts = lazy(() => import("./routes/Posts"));
const Download = lazy(() => import("./routes/Download"));
const Experimental = lazy(() => import("./routes/Experimental"));
const Purchase = lazy(() => import("./routes/Purchase"));

function App() {
  return (
    <Router>
      <Box sx={{ flexGrow: 1 }}>
        <Suspense
          fallback={
            <Backdrop open>
              <CircularProgress />
            </Backdrop>
          }
        >
          <NavBar />
          <Routes>
            <Route path="/docs/:postId" element={<Posts />} />
            <Route path="/docs" element={<Docs />} />
            <Route path="/about" element={<About />} />
            <Route path="/purchase/:step" element={<Purchase />} />
            <Route path="/purchase" element={<Purchase />} />
            <Route path="/download" element={<Download />} />
            <Route path="/experimental" element={<Experimental />} />
            <Route path="/" element={<Overview />} />
          </Routes>
          <Footer />
        </Suspense>
      </Box>
    </Router>
  );
}

export default App;
