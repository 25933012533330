import React from "react";
import logo from "../logo.svg";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MenuIcon from "@mui/icons-material/MenuRounded";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Link, useLocation, useNavigate } from "react-router-dom";

function NavBar() {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <a href="/">
          <Box sx={{ width: "48px", height: "48px" }}>
            <img src={logo} alt="logo" />
          </Box>
        </a>
        {small ? (
          <div>
            <IconButton
              id="navigation-button"
              aria-controls="navigation-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="navigation-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "navigation-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate("/");
                  handleClose();
                }}
                selected={location.pathname === "/"}
              >
                Overview
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/docs");
                  handleClose();
                }}
                selected={location.pathname.startsWith("/docs")}
              >
                Documentation
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/about");
                  handleClose();
                }}
                selected={location.pathname === "/about"}
              >
                About
              </MenuItem>
            </Menu>
          </div>
        ) : (
          <Stack direction="row" gap={1}>
            <Button
              color={location.pathname === "/" ? "primary" : "inherit"}
              component={Link}
              to="/"
            >
              Overview
            </Button>
            <Button
              color={
                location.pathname.startsWith("/docs") ? "primary" : "inherit"
              }
              component={Link}
              to="/docs"
            >
              Documentation
            </Button>
            <Button
              color={location.pathname === "/about" ? "primary" : "inherit"}
              component={Link}
              to="/about"
            >
              About
            </Button>
          </Stack>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
