import logo from "../assets/monotone.png";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";

function Footer() {
  return (
    <footer>
      <Container maxWidth="md" sx={{ mt: 2 }}>
        <Divider />
        <Toolbar sx={{ gap: 2 }}>
          <a href="/">
            <Box sx={{ width: "24px", height: "24px" }}>
              <img src={logo} alt="monotone logo" />
            </Box>
          </a>
          <Link href="/" underline="hover" color="inherit" variant="caption">
            Home
          </Link>
          <Link
            href="/docs"
            underline="hover"
            color="inherit"
            variant="caption"
          >
            Documentation
          </Link>
          <Link
            href="/about"
            underline="hover"
            color="inherit"
            variant="caption"
          >
            About
          </Link>
        </Toolbar>
      </Container>
    </footer>
  );
}

export default Footer;
